import { useAuthStore } from '@/core/stores/auth';
import { useMasterDataStore } from '@/core/stores/masterData';
import {
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
} from '@/shared/configs/apiConfig';

import AlertCentreService from './AlertCentreService';
import FFAPriceReleaseService from './FFAPriceReleaseService';
import FloatingStorageService from './FloatingStorageService';
import GlobalCalcService from './GlobalCalcService';
import OffsetCalculatorService from './OffsetCalculatorService';
import PriceService from './PriceService';
import RoutesService from './RoutesService';
import SettlementService from './SettlementService';
import WebhookService from './WebhookService';

const getRefreshTokenFn = () => useAuthStore().refreshToken();
const logoutFn = () => useAuthStore().logout();
const handle409Conflict = () => useMasterDataStore().handle409Conflict();
const updateMandatoryUpdateAvailable = (bool: boolean) =>
  useMasterDataStore().updateMandatoryUpdateAvailable(bool);
const updateRateLimiterEncountered = (bool: boolean) =>
  useMasterDataStore().updateRateLimiterEncountered(bool);

const apiBaseArgs = [
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
    updateMandatoryUpdateAvailableFn: updateMandatoryUpdateAvailable,
    updateRateLimiterEncounteredFn: updateRateLimiterEncountered,
  },
] as const;

const priceService = new PriceService(...apiBaseArgs);
const settlementService = new SettlementService(...apiBaseArgs);
const offsetCalculatorService = new OffsetCalculatorService(...apiBaseArgs);
const ffaPriceReleaseService = new FFAPriceReleaseService(...apiBaseArgs);
const alertCentreService = new AlertCentreService(...apiBaseArgs);
const routesService = new RoutesService(...apiBaseArgs);
const webhookService = new WebhookService(...apiBaseArgs);
const floatingStorageService = new FloatingStorageService(...apiBaseArgs);
const globalCalcService = new GlobalCalcService(...apiBaseArgs);

export {
  alertCentreService,
  ffaPriceReleaseService,
  floatingStorageService,
  globalCalcService,
  offsetCalculatorService,
  priceService,
  routesService,
  settlementService,
  webhookService,
};
