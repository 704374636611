import type {
  NavigationGuardNext,
  RouteLocation,
  RouteRecordRaw,
} from 'vue-router';

import { useAuthStore } from '@/core/stores/auth';
import RouteView from '@/shared/components/RouteView.vue';
import AppLayout from '@/shared/layout/AppLayout.vue';
import FullScreenLayout from '@/shared/layout/FullScreenLayout.vue';
import { features, permissions } from '@/shared/misc/constants';

const DiscoverPage = () =>
  import('@/products/freight/pages/discover/DiscoverPage.vue');

const SettlementTrackerPage = () =>
  import(
    '@/products/freight/pages/settlement-tracker/SettlementTrackerPage.vue'
  );
const SpreadChartingPage = () =>
  import('@/products/freight/pages/spread/index.vue');

const PriceEvolutionPage = () =>
  import('@/products/freight/pages/price-evolution/index.vue');

const SubmissionPage = () =>
  import('@/products/freight/pages/submission/SubmissionPage.vue');

const PriceCalculatorPage = () =>
  import('@/products/freight/pages/price-calculator/index.vue');

const GlobalCalulatorPage = () =>
  import('@/products/freight/pages/global-calculator/GlobalCalculatorPage.vue');

const OptiIntroPage = () => import('@/products/freight/pages/opti/index.vue');

const NeutralIntroPage = () =>
  import('@/products/freight/pages/neutral/NeutralIntro.vue');

const OffsetCalculatorPage = () =>
  import('@/products/freight/pages/offset-calculator/index.vue');

const FFASubmissionPage = () =>
  import('@/products/freight/pages/ffa/submission/FFASubmissionPage.vue');

const FFADiscoverPage = () =>
  import('@/products/freight/pages/ffa/discover/FFADiscoverPage.vue');

const AlertCentrePage = () =>
  import('@/products/freight/pages/alert-centre/AlertCentrePage.vue');

const BasicDashboardPage = () =>
  import('@/products/freight/pages/basic-dashboard/BasicDashboardPage.vue');

const RoutesDiscoverPage = () =>
  import('@/products/freight/pages/routes/discover/RoutesDiscoverPage.vue');

const FloatingStoragePage = () =>
  import('@/products/freight/pages/floating-storage/FloatingStoragePage.vue');

const FloatingStorageIntroPage = () =>
  import('@/products/freight/pages/floating-storage/Intro.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/freight',
    component: RouteView,
    children: [
      {
        path: '',
        component: () => '',
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore();
          const lngFreightPlatformSubscription =
            authStore.availableSubscriptions?.find(
              (s) => s.product === 'lng-freight-platform',
            );
          if (lngFreightPlatformSubscription?.plan === 'basic-plan') {
            next('/freight/basic-dashboard');
          } else if (lngFreightPlatformSubscription?.plan === 'no-plan') {
            next('/freight-intro');
          } else {
            next('/freight/spark30');
          }
        },
      },
      {
        path: 'ffa',
        redirect: {
          name: features.FREIGHT_FFA_DISCOVER,
          params: { contractId: 'spark30ffa' },
        },
      },

      // to redirect old route '/freight/discover?contractId=...' to new route
      {
        path: 'discover',
        component: () => '',
        beforeEnter: (to, from, next) => {
          if (to.query.contractId) {
            next(`/freight/${to.query.contractId}`);
          } else {
            next('/freight/spark30');
          }
        },
      },

      // to redirect old route '/freight/ffa/discover?contractId=...' to new route
      {
        path: 'ffa/discover',
        component: () => '',
        beforeEnter: (to, from, next) => {
          if (to.query.contractId) {
            next(`/freight/ffa/${to.query.contractId}`);
          } else {
            next('/freight/ffa/spark30ffa');
          }
        },
      },
      {
        path: '/freight/basic-dashboard',
        redirect: '/freight/basic-dashboard/spark30',
        alias: ['/basic-dashboard'],
      },
      {
        path: '/freight/basic-dashboard/:contractId',
        name: features.FREIGHT_BASIC_DASHBOARD,
        component: BasicDashboardPage,
        meta: {
          title: 'Basic Dashboard',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: ':contractId',
        name: features.FREIGHT_DISCOVER,
        component: DiscoverPage,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore();
          const lngFreightPlatformSubscription =
            authStore.availableSubscriptions?.find(
              (s) => s.product === 'lng-freight-platform',
            );
          if (lngFreightPlatformSubscription?.plan === 'basic-plan') {
            next('/freight/basic-dashboard');
          } else if (lngFreightPlatformSubscription?.plan === 'no-plan') {
            next('/freight-intro');
          } else {
            next();
          }
        },

        meta: {
          title: 'Discover',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
          featureIdTemplate: `${features.FREIGHT_PHYSICAL_PREFIX}{contractId}`,
        },
      },

      {
        path: 'ffa/:contractId',
        name: features.FREIGHT_FFA_DISCOVER,
        component: FFADiscoverPage,

        meta: {
          title: 'Discover',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
          featureIdTemplate: `${features.FREIGHT_FFA_PREFIX}{contractId}`,
        },
      },
      {
        path: 'indexation',
        alias: 'settlement',
        name: features.FREIGHT_SETTLEMENT_TRACKER,
        component: SettlementTrackerPage,
        meta: {
          title: 'Indexation',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
        },
      },
      {
        path: 'spread',
        name: features.FREIGHT_SPREAD,
        component: SpreadChartingPage,
        meta: {
          title: 'Spread Charting',
          product: 'lng-freight-platform',
          requiresAuth: true,
          permissions: [{ name: permissions.ACCESS_PRICE_SPREADS }],
          basic: false,
          layout: AppLayout,
        },
      },
      {
        path: 'historical-data',
        name: features.FREIGHT_PRICE_EVOLUTION,
        component: PriceEvolutionPage,
        alias: [
          'signals',
          'signals/price-evolution',
          'signals/historical-data',
        ],
        meta: {
          title: 'Historical Data',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
        },
      },
      {
        path: '/freight/offset-calculator',
        name: features.FREIGHT_OFFSET_CALCULATOR,
        component: OffsetCalculatorPage,
        alias: ['/offset-calculator'],
        meta: {
          title: 'Offset Calculator',
          product: 'lng-freight-platform',
          requiresAuth: true,
          permissions: [{ name: permissions.ACCESS_OFFSET_CALCULATOR }],
          basic: false,
          layout: AppLayout,
        },
      },
      {
        path: '/freight/alert-centre',
        name: features.FREIGHT_ALERT_CENTRE,
        component: AlertCentrePage,
        alias: ['/alert-centre'],
        meta: {
          title: 'Alert Centre',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'routes/discover',
        name: features.FREIGHT_ROUTES_DISCOVER,
        component: RoutesDiscoverPage,
        meta: {
          title: 'Routes',
          product: 'lng-freight-platform',
          requiresAuth: true,
          permissions: [
            { name: permissions.PREFIX_ACCESS_ROUTES, prefix: true },
          ],
          basic: true,
          layout: AppLayout,
        },
      },
      {
        path: 'global-calculator',
        name: features.FREIGHT_GLOBAL_CALCULATOR,
        component: GlobalCalulatorPage,
        meta: {
          title: 'Global Calculator',
          product: 'lng-freight-platform',
          requiresAuth: true,
          permissions: [{ name: permissions.ACCESS_GLOBAL_CALCULATOR }],
          basic: false,
          layout: AppLayout,
        },
      },
      {
        path: 'round-trip-calculator',
        name: features.FREIGHT_PRICE_CALCULATOR,
        component: PriceCalculatorPage,
        alias: ['price-calculator'],
        meta: {
          title: 'Round Trip Calculator',
          product: 'lng-freight-platform',
          requiresAuth: true,
          permissions: [{ name: permissions.ACCESS_PRICE_CALCULATOR }],
          basic: false,
          layout: AppLayout,
        },
      },
      {
        path: 'opti-intro',
        name: features.FREIGHT_OPTI,
        component: OptiIntroPage,
        // alias: ['/opti-intro'],
        meta: {
          title: 'Opti',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: true,
          hideBannerMessage: true,
          layout: AppLayout,
        },
      },
      {
        path: 'floating-storage',
        name: features.FREIGHT_FLOATING_STORAGE,
        component: FloatingStoragePage,
        alias: ['floating-storage'],
        meta: {
          title: 'Floating Storage',
          product: 'lng-freight-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
        },
        beforeEnter: (
          to: RouteLocation,
          from: RouteLocation,
          next: NavigationGuardNext,
        ) => {
          const hasAccess = useAuthStore().hasPermission(
            permissions.ACCESS_FLOATING_STORAGE,
            'lng-freight-platform',
          );

          if (hasAccess) {
            next();
          } else {
            next('/freight/floating-storage/intro');
          }
        },
      },
      {
        path: 'floating-storage/intro',
        name: features.FREIGHT_FLOATING_STORAGE_INTRO,
        component: FloatingStorageIntroPage,
        alias: ['floating-storage-intro'],
        meta: {
          title: 'Floating Storage Intro',
          product: 'lng-freight-platform',
          hideBannerMessage: true,
          requiresAuth: true,
          layout: AppLayout,
        },
      },
    ],
  },
  {
    path: '/freight/submit/',
    name: 'submit',
    component: SubmissionPage,
    alias: ['/submit/'],
    meta: {
      title: 'All Submissions',
      product: 'lng-freight-platform',
      requiresAuth: true,
      basic: true,
      layout: FullScreenLayout,
    },
  },
  {
    path: '/freight/ffa/submit/:contractId',
    name: features.FREIGHT_FFA_SUBMISSION,
    component: FFASubmissionPage,
    meta: {
      title: 'FFA Submission',
      product: 'lng-freight-platform',
      requiresAuth: true,
      basic: false,
      layout: FullScreenLayout,
    },
  },
  {
    path: '/freight/neutral-intro',
    name: features.FREIGHT_NEUTRAL_INTRO,
    component: NeutralIntroPage,
    alias: ['/neutral-intro'],
    meta: {
      title: 'Neutral',
      product: 'lng-freight-platform',
      // requiresAuth: false,
      basic: true,
      layout: AppLayout,
    },
  },
];

export default routes;
