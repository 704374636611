<script setup lang="ts">
import { useMenuStore } from '@/core/stores/menu';

import TheSidebarMainMenu from './TheSidebarMainMenu.vue';
import TheSidebarSubMenu from './TheSidebarSubMenu.vue';

const menuStore = useMenuStore();
</script>

<template>
  <!-- uses z-index here because we want the active page arrow 
       indicator to be above the right main content-->
  <div
    class="sticky top-0 z-[970] hidden h-[100svh] grid-cols-[48px_184px] transition-[grid-template-rows_opacity] data-[expanded=false]:grid-cols-[48px_0px] hd:grid"
    :data-expanded="menuStore.isSubmenuExpanded"
  >
    <div class="relative min-h-0">
      <TheSidebarMainMenu class="absolute z-50" />
    </div>

    <div class="relative min-h-0">
      <div
        class="absolute bottom-0 left-0 top-0 w-[calc(100%-0.5rem)] bg-[#222a38]"
      ></div>
      <TheSidebarSubMenu />
    </div>
  </div>
</template>
