<script setup lang="ts">
import { computed } from 'vue';

import PageLevelMaintenanceMessage from '@/core/components/PageLevelMaintenanceMessage.vue';
import TheSidebar from '@/core/components/the-nav/TheSidebar.vue';
import TheTopMenu from '@/core/components/the-nav/TheTopMenu.vue';
import { useAuthStore } from '@/core/stores/auth';
import { useMenuStore } from '@/core/stores/menu';

interface Props {
  isPremium?: boolean;
}
withDefaults(defineProps<Props>(), {
  isPremium: false,
});

const authStore = useAuthStore();
const menuStore = useMenuStore();

const loggedIn = computed(() => {
  return authStore.loggedIn;
});
</script>

<template>
  <div>
    <div v-if="!loggedIn" class="flex h-full items-center justify-center">
      <SparkIcon
        icon="spinner"
        class="animate-spin-fast text-green-500"
        height="48"
        width="48"
      ></SparkIcon>
    </div>
    <div v-else class="hd:grid hd:min-h-full hd:grid-cols-[auto_1fr]">
      <div class="hd:min-h-0">
        <TheSidebar />
      </div>

      <div class="hd:min-h-0 hd:min-w-0">
        <div
          class="hd:h-full hd:max-h-full"
          :class="{ 'hd:-ml-2': menuStore.isSubmenuExpanded }"
        >
          <TheTopMenu />

          <div class="pt-12 hd:min-w-0 hd:pt-0">
            <PageLevelMaintenanceMessage />

            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
