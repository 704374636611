import {
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
} from '@/shared/configs/apiConfig';

import { useAuthStore } from '../stores/auth';
import { useMasterDataStore } from '../stores/masterData';
import AnalyticsService from './AnalyticsService';
import AuthService from './AuthService';
import ConnectService from './ConnectService';
import MaintenanceService from './Maintenance';
import MapService from './MapService';
import MarketingService from './MarketingService';
import MasterDataService from './MasterDataService';
import PollService from './PollService';
import PriceReleaseService from './PriceReleaseService';
import PriceSignalService from './PriceSignalService';
import SparkSignalsService from './SparkSignalsService';
import StaticDataService from './StaticDataService';
import UserAccountService from './UserAccountService';

const getRefreshTokenFn = () => useAuthStore().refreshToken();
const logoutFn = () => useAuthStore().logout();
const handle409Conflict = () => useMasterDataStore().handle409Conflict();
const updateMandatoryUpdateAvailable = (bool: boolean) =>
  useMasterDataStore().updateMandatoryUpdateAvailable(bool);
const updateRateLimiterEncountered = (bool: boolean) =>
  useMasterDataStore().updateRateLimiterEncountered(bool);

const apiBaseArgs = [
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
    updateMandatoryUpdateAvailableFn: updateMandatoryUpdateAvailable,
    updateRateLimiterEncounteredFn: updateRateLimiterEncountered,
  },
] as const;

const masterDataService = new MasterDataService(...apiBaseArgs);
const userAccountService = new UserAccountService(...apiBaseArgs);
const marketingService = new MarketingService(...apiBaseArgs);
const staticDataService = new StaticDataService(...apiBaseArgs);
const analyticsService = new AnalyticsService(...apiBaseArgs);
const priceReleaseService = new PriceReleaseService(...apiBaseArgs);
const authService = new AuthService(...apiBaseArgs);
const maintenanceService = new MaintenanceService(...apiBaseArgs);
const priceSignalService = new PriceSignalService(...apiBaseArgs);
const pollService = new PollService(...apiBaseArgs);
const sparkSignalsService = new SparkSignalsService(...apiBaseArgs);
const mapService = new MapService(...apiBaseArgs);
const connectService = new ConnectService(...apiBaseArgs);

export {
  analyticsService,
  authService,
  connectService,
  maintenanceService,
  mapService,
  marketingService,
  masterDataService,
  pollService,
  priceReleaseService,
  priceSignalService,
  sparkSignalsService,
  staticDataService,
  userAccountService,
};
