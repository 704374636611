<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

import { useMenuStore } from '@/core/stores/menu';
import SparkScroller from '@/shared/components/SparkScroller.vue';

import CallToActionLink from './CallToActionLink.vue';
import TheSidebarSubMenuSectionLink from './TheSidebarSubMenuSectionLink.vue';
import TheSidebarSubMenuSectionParent from './TheSidebarSubMenuSectionParent.vue';

const PLAN: Record<string, string> = {
  'trial-plan': 'Trial',
  'basic-plan': 'Basic',
};

const menuStore = useMenuStore();

const { subMenuConfig } = storeToRefs(menuStore);

const scroller = ref<InstanceType<typeof SparkScroller> | null>(null);

watch(
  () => subMenuConfig.value.title,
  (titleValue) => {
    if (titleValue !== '') {
      if (scroller.value) {
        scroller.value.scrollToTop();
      }
    }
  },
);
</script>

<template>
  <SparkScroller
    ref="scroller"
    :space-between-content-and-scrollbar="0"
    :scrollbar-width="0"
    :mask-height="48"
    class="relative h-full max-h-full"
  >
    <div class="mr-2 h-screen border-r border-gray-700 bg-[#222a38] pb-5 pl-5">
      <div class="flex items-center justify-between pr-3">
        <div
          class="flex h-[48px] items-center text-base font-bold"
          :class="[subMenuConfig.titleColor]"
        >
          {{ subMenuConfig.title }}
        </div>

        <div
          v-if="
            subMenuConfig.subscriptionPlan === 'basic-plan' ||
            subMenuConfig.subscriptionPlan === 'trial-plan'
          "
          class="ml-auto rounded bg-orange-600 px-1 py-0.5 text-[0.6rem] font-semibold leading-none text-white"
        >
          {{ PLAN[subMenuConfig.subscriptionPlan] }}
        </div>
      </div>

      <div class="space-y-4">
        <div v-if="subMenuConfig.callToActions.length" class="mt-[1px]">
          <CallToActionLink
            v-for="cta in subMenuConfig.callToActions"
            :key="cta.description"
            :as="cta.renderAs"
            :href="cta.href"
            :open-crisp-chat-if-available="cta.openCrispChatIfAvailable"
            :pre-fill-crisp-message="cta.preFillCrispChatMessage"
          >
            {{ cta.description }}
          </CallToActionLink>
        </div>

        <div class="space-y-8">
          <div
            v-for="section in subMenuConfig.sections"
            :key="section.title"
            class="space-y-4"
          >
            <div class="text-sm font-bold tracking-widest text-gray-400">
              {{ section.title }}
            </div>
            <div class="space-y-2">
              <template v-for="item in section.items" :key="item.title">
                <TheSidebarSubMenuSectionParent
                  v-if="!item.to && item.items"
                  :id="item.uiTourId"
                  :icon="item.icon"
                  :title="item.title"
                  :items="item.items"
                  :is-locked="item.isLocked"
                  :show-red-dot="item.showRedDot"
                />
                <TheSidebarSubMenuSectionLink
                  v-if="item.to && !item.items"
                  :to="item.to"
                  :icon="item.icon"
                  :title="item.title"
                  :sub-title="item.subTitle"
                  :is-locked="item.isLocked"
                  :show-red-dot="item.showRedDot"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SparkScroller>
</template>
