<script setup lang="ts">
import { computed, inject } from 'vue';

import { useMenuStore } from '@/core/stores/menu';
import { assertIsDefined } from '#/utils/asserts';

import { THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY } from './share';

const props = defineProps<{
  commodities: Array<{
    to: string;
    key: 'lng' | 'gas';
    title: string;
  }>;
}>();

const context = inject(THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY);
assertIsDefined(
  context,
  'TheSidebarMainMenuCommoditySwitch requires TheSidebarMainMenu as parent',
);
const { isExpanded } = context;

const menuStore = useMenuStore();

const commoditiesDisplay = computed(() => {
  return props.commodities
    .map((commodity) => {
      return {
        ...commodity,
        visible: isExpanded.value
          ? true
          : commodity.key === menuStore.activeCommodity,
        showRedDot: false,
      };
    })
    .sort((a) => {
      // by whether route is active
      if (a.key === menuStore.activeCommodity) {
        return -1;
      }
      return 0;
    });
});
</script>

<template>
  <div
    class="col-span-2 grid h-11 grid-cols-[48px_0px] text-gray-100 transition-[grid-template-columns] data-[expanded=true]:grid-cols-[minmax(48px,50%)_50%]"
    :data-expanded="isExpanded"
  >
    <RouterLink
      v-for="commodity in commoditiesDisplay"
      :key="commodity.title"
      :to="commodity.to"
      class="overflow-hidden text-gray-400 transition-all duration-300"
      :class="{
        hidden: !commodity.visible,
        'hover:bg-gray-700/50 hover:text-gray-100':
          menuStore.activeCommodity !== commodity.key,
        'opacity-0': menuStore.activeCommodity !== commodity.key && !isExpanded,
        'bg-green-700 text-white hover:text-white':
          commodity.key === 'lng' && menuStore.activeCommodity === 'lng',
        'bg-gas-600 text-white hover:text-white':
          commodity.key === 'gas' && menuStore.activeCommodity === 'gas',
      }"
    >
      <div class="relative flex h-full items-center justify-center">
        <span class="font-display font-bold">{{ commodity.title }}</span>
        <sup
          v-if="commodity.showRedDot"
          class="absolute right-2 top-1 block h-[6px] w-[6px] rounded-full bg-red"
        ></sup>
      </div>
    </RouterLink>
  </div>
</template>
