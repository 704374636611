<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useMenuStore } from '@/core/stores/menu';

import {
  accountFeatures,
  commodityConfig,
  miscFeatures,
} from './mainMenuConfig';
import { THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY } from './share';
import TheSidebarMainMenuCommoditySwitch from './TheSidebarMainMenuCommoditySwitch.vue';
import TheSidebarMainMenuLink from './TheSidebarMainMenuLink.vue';
import TheSidebarMainMenuProductLink from './TheSidebarMainMenuProductLink.vue';

const IS_PROD = import.meta.env.MODE === 'production';

const isExpanded = ref(false);

const route = useRoute();
const menuStore = useMenuStore();

const { crossProductFeaturesMenu } = storeToRefs(menuStore);

function onMouseEnter() {
  isExpanded.value = true;
}

function onMouseLeave() {
  isExpanded.value = false;
}

provide(THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY, {
  isExpanded: computed(() => isExpanded.value),
});

// Reset isExpanded when route changes
watch(
  () => route.path,
  () => {
    if (isExpanded.value) {
      isExpanded.value = false;
    }
  },
);
</script>

<template>
  <div
    class="scrollbar-none grid h-full max-h-full grid-cols-[48px_0px] grid-rows-[auto_1fr_auto] overflow-y-auto overflow-x-hidden border-r-2 bg-blue-900 transition-[grid-template-columns,border-color] data-[expanded=true]:grid-cols-[48px_100px]"
    :class="menuStore.mainMenuConfig.borderRightColor"
    :data-expanded="isExpanded"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="col-span-2 grid grid-cols-[subgrid]">
      <RouterLink
        to="/dashboard"
        class="col-span-2 grid h-14 grid-cols-[subgrid] hover:bg-gray-700/50"
      >
        <div class="flex items-center justify-center">
          <SparkIcon
            icon="logo"
            :class="{ 'text-green-500': IS_PROD, 'text-orange-500': !IS_PROD }"
            size="24"
          />
        </div>
        <div
          class="flex items-center overflow-hidden pt-[2px] font-display text-2xl font-semibold leading-none opacity-0 transition-opacity data-[expanded=true]:opacity-100"
          :class="{ 'text-green-500': IS_PROD, 'text-orange-500': !IS_PROD }"
          :data-expanded="isExpanded"
        >
          Spark
        </div>
      </RouterLink>

      <hr class="col-span-2 border-t border-gray-700" />

      <!-- Commodity menu -->
      <TheSidebarMainMenuCommoditySwitch
        v-if="menuStore.showCommoditySwitch"
        :commodities="commodityConfig"
      />

      <hr class="col-span-2 border-t border-gray-700" />

      <!-- Product links menu -->
      <TheSidebarMainMenuProductLink
        :key="item.title"
        v-for="item in menuStore.mainMenuConfig.items[
          menuStore.activeCommodity
        ]"
        :to="item.to"
        :icon="item.icon"
        :subscription-plan="item.subscriptionPlan"
        :show-red-dot="item.showRedDot"
      >
        {{ item.title }}
      </TheSidebarMainMenuProductLink>

      <hr class="col-span-2 border-t border-gray-700" />
    </div>

    <!-- Cross Product Features menu -->
    <div class="col-span-2 grid grid-cols-[subgrid] content-start">
      <template
        v-for="item in crossProductFeaturesMenu[menuStore.activeCommodity]"
        :key="item.title"
      >
        <TheSidebarMainMenuLink v-if="item.to" :to="item.to" :icon="item.icon">
          {{ item.title }}
        </TheSidebarMainMenuLink>
      </template>
    </div>

    <!-- Misc Features menu -->
    <div class="col-span-2 grid grid-cols-[subgrid]">
      <hr class="col-span-2 border-t border-gray-700" />

      <TheSidebarMainMenuLink
        v-for="item in miscFeatures"
        :key="item.title"
        :to="item.to"
        :icon="item.icon"
      >
        {{ item.title }}
      </TheSidebarMainMenuLink>

      <hr class="col-span-2 border-t border-gray-700" />

      <TheSidebarMainMenuLink
        v-for="item in accountFeatures"
        :key="item.title"
        :to="item.to"
        :icon="item.icon"
      >
        {{ item.title }}
      </TheSidebarMainMenuLink>
    </div>
  </div>
</template>

<style scoped>
.scrollbar-none::-webkit-scrollbar {
  display: none;
}
.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
