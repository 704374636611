import { useAuthStore } from '@/core/stores/auth';
import { useMasterDataStore } from '@/core/stores/masterData';
import {
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
} from '@/shared/configs/apiConfig';

import AccessService from './AccessService';

const getRefreshTokenFn = () => useAuthStore().refreshToken();
const logoutFn = () => useAuthStore().logout();
const handle409Conflict = () => useMasterDataStore().handle409Conflict();
const updateMandatoryUpdateAvailable = (bool: boolean) =>
  useMasterDataStore().updateMandatoryUpdateAvailable(bool);
const updateRateLimiterEncountered = (bool: boolean) =>
  useMasterDataStore().updateRateLimiterEncountered(bool);

const apiBaseArgs = [
  DEFAULT_API_CONFIG,
  DEFAULT_API_META,
  getRefreshTokenFn,
  logoutFn,
  {
    handle409ConflictFn: handle409Conflict,
    updateMandatoryUpdateAvailableFn: updateMandatoryUpdateAvailable,
    updateRateLimiterEncounteredFn: updateRateLimiterEncountered,
  },
] as const;

const accessService = new AccessService(...apiBaseArgs);

export { accessService };
