import type { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { AxiosHeaders } from 'axios';

import CommonApiBase from '#/interfaces/spark-api/ApiBase';
import type { ApiConfig, ApiMeta } from '#/interfaces/spark-api/types';

const AUTH_URL = '/oauth/token';

export default class ApiBase extends CommonApiBase {
  getRefreshTokenFn: () => Promise<void>;
  logoutFn: () => Promise<void>;
  handle409ConflictFn: () => void;
  updateMandatoryUpdateAvailableFn: (val: boolean) => void;
  updateRateLimiterEncounteredFn: (val: boolean) => void;

  constructor(
    config: ApiConfig,
    meta: ApiMeta,
    getRefreshTokenFn: () => Promise<void>,
    logoutFn: () => Promise<void>,
    opts: {
      handle409ConflictFn: () => void;
      updateMandatoryUpdateAvailableFn: (val: boolean) => void;
      updateRateLimiterEncounteredFn: (val: boolean) => void;
    },
  ) {
    super(config, meta);
    this.getRefreshTokenFn = getRefreshTokenFn;
    this.logoutFn = logoutFn;
    this.handle409ConflictFn = opts?.handle409ConflictFn;
    this.updateMandatoryUpdateAvailableFn =
      opts?.updateMandatoryUpdateAvailableFn;
    this.updateRateLimiterEncounteredFn = opts?.updateRateLimiterEncounteredFn;
  }

  requestInterceptor(
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig {
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }

    config.headers['X-Client-App-Browsing-ID'] =
      this.meta.getBrowsingSessionId();

    return config;
  }

  responseErrorHandler(error: AxiosError) {
    if (error?.response?.status === 426) {
      this.updateMandatoryUpdateAvailableFn?.(true);
    }

    if (error?.response?.status === 409) {
      this.handle409ConflictFn?.();
    }

    const isAuthRequest = error?.config?.url?.includes(AUTH_URL);
    const isRetry = error?.config?.headers?.retry_attempt;

    if (error.response?.status === 401 && !isAuthRequest && !isRetry) {
      return this.getRefreshTokenFn().then(() => {
        if (error?.config?.headers) {
          error.config.headers.retry_attempt = 1;
        }
        if (error.config) {
          return this.$http.request(error.config);
        }
      });
    }

    if (error.response?.status === 401 && isRetry) {
      // handle refresh failure
      console.warn('auth error on retry - logging out');
      this.logoutFn();
    } else if (error.response?.status === 429) {
      this.updateRateLimiterEncounteredFn?.(true);
    } else {
      return Promise.reject(error);
    }
  }
}
